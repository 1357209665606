import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import EmailValidator from 'email-validator';

import { FormInput } from 'seedly-component-library';
import Button from 'seedly-component-library/button-new';
import { signIn } from 'entity/auth/authDispatch';
import { RootState } from 'app/reducer';
import mixpanel, { trackedEvents } from 'utils/mixpanel';

import { LoginContext } from './LoginContext';
import useHandleUnverifiedEmailError from './hooks/useHandleUnverifiedEmailError';

const EmailSignInForm = () => {
  const dispatch = useDispatch();
  const { navigate } = useContext(LoginContext);

  const isLoading = useSelector(
    (state: RootState) => state.auth.api.isFetching,
  );

  useHandleUnverifiedEmailError({
    onEmailNotVerified: () => {
      navigate('verify-email');
    },
  });

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validate: values => {
      const errors: { email?: string; password?: string } = {};
      if (!values.email) {
        errors.email = 'Email is required';
      } else if (!EmailValidator.validate(values.email)) {
        errors.email = 'Invalid email address';
      }
      if (!values.password) {
        errors.password = 'Password is required';
      }
      return errors;
    },
    onSubmit: values => {
      dispatch(signIn(values));
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldTouched,
  } = formik;

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={e => {
        e.preventDefault();
        mixpanel.track(trackedEvents.LoginSignUpModal, {
          param: 'login_email',
        });
        sessionStorage.removeItem('user-signedup');
        handleSubmit();
      }}
      onReset={handleReset}
    >
      <div className="flex flex-col w-full justify-between gap-8">
        <div className="flex flex-col w-full gap-4">
          <FormInput
            type="email"
            name="email"
            onChange={e => setFieldValue('email', e.currentTarget.value)}
            onBlur={() => setFieldTouched('email')}
            value={values.email}
            error={errors.email && touched.email && errors.email}
            label="Email"
            placeholder="Your email"
            data-testid="email-input"
            width="100%"
            disabled={isLoading}
          />
          <FormInput
            type="password"
            name="password"
            onChange={e => setFieldValue('password', e.currentTarget.value)}
            onBlur={() => setFieldTouched('password')}
            value={values.password}
            error={errors.password && touched.password && errors.password}
            label="Password"
            placeholder="Your password"
            data-testid="password-input"
            width="100%"
            disabled={isLoading}
          />
          <span
            className="text-xs text-neutral-500 hover:underline cursor-pointer"
            onClick={() => navigate('forget-password')}
          >
            Forgot your password?
          </span>
        </div>
        <Button
          size="lg"
          data-testid="login-button"
          isLoading={isLoading}
          isBlock
          disabled={Boolean(!values.email || !values.password)}
          type="submit"
        >
          Login
        </Button>
      </div>
    </form>
  );
};

export default EmailSignInForm;
