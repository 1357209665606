import React, { useContext } from 'react';

import EmailSignInForm from './EmailSignInForm';
import LoginButton from '../LoginButton';

import { LoginContext } from './LoginContext';

const MainSceneLogin = () => {
  const { navigate } = useContext(LoginContext);

  return (
    <div className="flex flex-col gap-6 w-full items-center pt-10 lg:pt-0">
      <p className="text-2xl font-bold text-center">Log in to Seedly</p>
      <div className="flex flex-col gap-3 w-full items-center">
        <LoginButton variant="google" trackingParam="login_google" />
        <LoginButton trackingParam="login_facebook" variant="facebook" />
        <LoginButton trackingParam="login_apple" variant="apple" />
      </div>
      <div className="flex gap-2 items-center w-full">
        <div className="horizontal-divider" />
        <p className="text-neutral-500">OR</p>
        <div className="horizontal-divider" />
      </div>
      <EmailSignInForm />
      <p>
        New to Seedly?{' '}
        <span
          className="text-sm font-bold text-blue-500 cursor-pointer"
          onClick={() => navigate('main-sign-up')}
        >
          Create an account
        </span>
      </p>
    </div>
  );
};

export default MainSceneLogin;
