import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ChevronLeft } from 'react-feather';

import { closeLoginModal } from 'dispatcher/modalDispatcher';
import Dialog from 'seedly-component-library/dialog';
import { RootState } from 'reducer';

import { LoginContext } from './LoginContext';
import useTrackOpenLogin from './hooks/useTrackOpenLogin';
import useSceneComponent from './hooks/useSceneComponent';

const LoginModal = () => {
  const isLoginModalOpen = useSelector(
    (state: RootState) => state.modal.isLoginModalOpen,
  );

  const dispatch = useDispatch();

  const { isBackAvailable, goBack, reset } = useContext(LoginContext);
  const SceneComponent = useSceneComponent();
  useTrackOpenLogin();

  const BackButton = () => (
    <ChevronLeft
      className="cursor-pointer"
      onClick={() => goBack()}
      data-testid="back-button"
    />
  );

  if (!isLoginModalOpen) return null;

  return (
    <Dialog
      isOpen={isLoginModalOpen}
      widthSize="sm"
      onCloseClick={() => {
        reset();
        dispatch(closeLoginModal());
      }}
      contentClassName="pt-14 px-6 pb-6"
    >
      {isBackAvailable && (
        <div className="absolute top-4 left-4">
          <BackButton />
        </div>
      )}
      {SceneComponent}
    </Dialog>
  );
};

export default LoginModal;
