import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import EmailValidator from 'email-validator';
import ReCAPTCHA from 'react-google-recaptcha';

import { FormInput } from 'app/seedly-component-library';
import Button from 'seedly-component-library/button-new';

import { signUp } from 'app/entity/auth/authDispatch';
import isEmpty from 'lodash/isEmpty';
import { RootState } from 'reducer';

const EmailSignUpForm = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.auth.api.isFetching,
  );

  // GOOGLE_CAPTCHA_SITE_TEST_KEY will be used in localhost
  const siteKey =
    process.env.GOOGLE_CAPTCHA_SITE_KEY ||
    process.env.GOOGLE_CAPTCHA_SITE_TEST_KEY;

  const recaptchaRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      name: '',
      gcaptchaResponse: '',
    },
    validate: values => {
      const errors: Partial<{
        email: string;
        password: string;
        name: string;
        gcaptchaResponse: string;
      }> = {};
      if (!values.email) {
        errors.email = 'Email is required';
      } else if (!EmailValidator.validate(values.email)) {
        errors.email = 'Invalid email address';
      }
      if (!values.password) {
        errors.password = 'Password is required';
      } else if (values.password.length < 6) {
        errors.password = 'Password must be at least 6 characters';
      }
      if (!values.name) {
        errors.name = 'Name is required';
      }
      if (!values.gcaptchaResponse) {
        errors.gcaptchaResponse = 'Please verify that you are not a robot';
      }
      return errors;
    },
    onSubmit: values => {
      dispatch(signUp({ ...values }));
      recaptchaRef.current.reset();
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldTouched,
  } = formik;

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <div className="flex flex-col gap-6 w-full items-center">
        <div className="flex flex-col gap-4 w-full">
          <FormInput
            type="email"
            name="email"
            onChange={e => setFieldValue('email', e.currentTarget.value)}
            onBlur={() => setFieldTouched('email')}
            value={values.email}
            error={errors.email && touched.email && errors.email}
            label="Email"
            placeholder="Your email"
            width="100%"
            disabled={isLoading}
          />
          <FormInput
            type="password"
            name="password"
            onChange={e => setFieldValue('password', e.currentTarget.value)}
            onBlur={() => setFieldTouched('password')}
            value={values.password}
            error={errors.password && touched.password && errors.password}
            label="Password"
            placeholder="Password"
            disabled={isLoading}
            width="100%"
            info="Password must be at least 6 characters"
          />
          <FormInput
            type="text"
            name="name"
            onChange={e => setFieldValue('name', e.currentTarget.value)}
            onBlur={() => setFieldTouched('name')}
            value={values.name}
            error={errors.name && touched.name && errors.name}
            label="Name"
            placeholder="Your name"
            width="100%"
            disabled={isLoading}
          />
          <div className="flex flex-col gap-1">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={siteKey}
              onChange={value => {
                setFieldValue('gcaptchaResponse', value);
              }}
            />
            {errors.gcaptchaResponse && touched.gcaptchaResponse && (
              <p className="text-red-500 text-xs">{errors.gcaptchaResponse}</p>
            )}
          </div>
        </div>
        <div className="flex w-full justify-end">
          <Button
            size="lg"
            isLoading={isLoading}
            disabled={!values.gcaptchaResponse && !isEmpty(errors)}
            type="submit"
          >
            Sign Up
          </Button>
        </div>
      </div>
    </form>
  );
};

export default EmailSignUpForm;
