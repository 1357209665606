import styled from 'styled-components';
import { media } from 'theme';
import colors from '../themes/colors';
import Space from '../space';

import { VariantTheme, VariantState, SizeTheme } from './InputTypes';

interface WrapperProps {
  variant: Record<VariantState, VariantTheme>;
  isError: boolean;
  isFocus: boolean;
  disabled: boolean;
  borderRadius: string;
}

export const Wrapper = styled(Space)<WrapperProps>`
  display: flex;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: ${props => props.borderRadius};
  border: ${props => {
    if (props.disabled) {
      return props.variant.disabled.border;
    }
    if (props.isError) {
      return props.variant.error.border;
    }
    if (props.isFocus) {
      return props.variant.active.border;
    }
    return props.variant.enabled.border;
  }};
  background-color: ${props => {
    if (props.disabled) {
      return props.variant.disabled.backgroundColor;
    }
    if (props.isError) {
      return props.variant.error.backgroundColor;
    }
    if (props.isFocus) {
      return props.variant.active.backgroundColor;
    }
    return props.variant.enabled.backgroundColor;
  }};

  ${props => {
    if (!props.isError && !props.disabled && !props.isFocus) {
      return `
          &:hover {
            background-color: ${props.variant.hover.backgroundColor};
            border: ${props.variant.hover.border};
          }
        `;
    }
  }}
`;

interface InputProps {
  disabled: boolean;
  sizeTheme: SizeTheme;
  disabledColor: string;
}

export const StyledInput = styled.input<InputProps>`
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  color: ${colors.neutral7};
  font-size: ${props => props.sizeTheme.fontSize};
  font-weight: ${props => props.sizeTheme.fontWeight};
  line-height: ${props => props.sizeTheme.lineHeight};

  ::placeholder {
    color: ${props => (props.disabled ? props.disabledColor : colors.neutral5)};
  }

  ${media.largeAndDown`
    font-size: 14px;
  `}

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
