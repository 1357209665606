import React, { useContext } from 'react';
import { LoginContext } from '../LoginContext';

import MainScene from '../MainSceneSignUp';
import MainSceneLogin from '../MainSceneLogin';
import EmailSignInForm from '../EmailSignInForm';
import EmailSignUpForm from '../EmailSignUpForm';
import ForgetPasswordForm from '../ForgetPasswordForm';
import VerifyEmailScene from '../VerifyEmailScene';

const useSceneComponent = () => {
  const { scene } = useContext(LoginContext);

  switch (scene) {
    case 'main-log-in':
      return <MainSceneLogin />;
    case 'main-sign-up':
      return <MainScene />;
    case 'log-in':
      return <EmailSignInForm />;
    case 'sign-up':
      return <EmailSignUpForm />;
    case 'verify-email':
      return <VerifyEmailScene />;
    case 'forget-password':
      return <ForgetPasswordForm />;
    default:
      return <MainScene />;
  }
};

export default useSceneComponent;
