import React, { HTMLProps, ReactNode, Ref, useState } from 'react';
import {
  InputVariantTheme,
  InputSizeTheme,
  InputShapeTheme,
} from './InputTheme';
import * as S from './styles';
import spacing from '../themes/spacing';

type CustomFields = 'size' | 'prefix' | 'onFocus' | 'onBlur' | 'ref' | 'as';

export interface InputProps
  extends Omit<HTMLProps<HTMLInputElement>, CustomFields> {
  ref?: Ref<HTMLInputElement>;
  error?: boolean;
  variant?: 'outline' | 'solid';
  shape?: 'rounded' | 'pill';
  size?: 'sm' | 'md' | 'lg';
  innerInputSpacing?: keyof typeof spacing;
  width?: string;
  disabled?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    error = '',
    variant = 'outline',
    shape = 'rounded',
    size = 'md',
    width = 'auto',
    disabled = false,
    prefix = null,
    suffix = null,
    innerInputSpacing = 'xs',
    onFocus = () => {},
    onBlur = () => {},
    onChange,
    ...rest
  } = props;

  const [isFocus, setIsFocus] = useState(false);
  const variantTheme = InputVariantTheme[variant];
  const shapeTheme = InputShapeTheme[shape];
  const sizeTheme = InputSizeTheme[size];

  return (
    <S.Wrapper
      width={width}
      height={sizeTheme.height}
      variant={variantTheme}
      borderRadius={shapeTheme.borderRadius}
      disabled={disabled}
      isError={!!error}
      isFocus={isFocus}
      size={innerInputSpacing}
    >
      {prefix}
      <S.StyledInput
        ref={ref}
        sizeTheme={sizeTheme}
        disabledColor={variantTheme.disabled.color}
        onFocus={() => {
          setIsFocus(true);
          onFocus();
        }}
        onBlur={() => {
          setIsFocus(false);
          onBlur();
        }}
        disabled={disabled}
        onChange={onChange}
        {...rest}
      />
      {suffix}
    </S.Wrapper>
  );
});

export default Input;
