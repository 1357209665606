import React from 'react';

import AppleLoginButton from './AppleLoginButton';
import EmailLoginButton from './EmailLoginButton';
import FacebookLoginButton from './FacebookLoginButton';
import GoogleLoginButton from './GoogleLoginButton';

interface OwnProps {
  title?: string;
  variant: 'apple' | 'email' | 'facebook' | 'google';
  trackingParam?: string;
  isSignup?: boolean;
  isIconOnly?: boolean;
}

const LoginButton = (props: OwnProps) => {
  const { variant, title, trackingParam, isSignup = false, isIconOnly } = props;

  switch (variant) {
    case 'apple':
      return (
        <AppleLoginButton
          trackingParam={trackingParam}
          title={title}
          isSignup={isSignup}
          isIconOnly={isIconOnly}
        />
      );
    case 'email':
      return <EmailLoginButton isIconOnly={isIconOnly} />;
    case 'facebook':
      return (
        <FacebookLoginButton
          trackingParam={trackingParam}
          title={title}
          isSignup={isSignup}
          isIconOnly={isIconOnly}
        />
      );
    case 'google':
      return (
        <GoogleLoginButton
          trackingParam={trackingParam}
          title={title}
          isSignup={isSignup}
          isIconOnly={isIconOnly}
        />
      );
    default:
      return <EmailLoginButton />;
  }
};

export default LoginButton;
