import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import EmailValidator from 'email-validator';

import { FormInput } from 'app/seedly-component-library';
import Button from 'seedly-component-library/button-new';
import { forgetPassword } from 'app/entity/auth/authDispatch';

import { LoginContext } from './LoginContext';

const ForgetPasswordForm = () => {
  const [showCustomError, setShowCustomError] = useState(false);
  const dispatch = useDispatch();
  const { navigate } = useContext(LoginContext);

  return (
    <div className="flex flex-col gap-8 w-full">
      <div className="flex flex-col gap-1 w-full">
        <p className="text-base font-bold">Forgot password?</p>
        <p className="text-neutral-500">
          Let us know your email and we will send you a password reset link.
        </p>
      </div>
      <Formik
        initialValues={{ email: '' }}
        validate={values => {
          const errors: { email?: string } = {};
          if (!values.email) {
            errors.email = 'Email is required';
          } else if (!EmailValidator.validate(values.email)) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(
            forgetPassword(values, {
              onComplete: () => setSubmitting(false),
              onFailure: () => {
                setShowCustomError(true);
              },
            }),
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          handleReset,
          setFieldTouched,
          setFieldValue,
        }) => {
          return (
            <form
              style={{ width: '100%' }}
              onSubmit={handleSubmit}
              onReset={handleReset}
            >
              <div className="flex flex-col gap-8 w-full items-end">
                <FormInput
                  type="email"
                  name="email"
                  onChange={e => {
                    setShowCustomError(false);
                    setFieldValue('email', e.currentTarget.value);
                  }}
                  onBlur={() => setFieldTouched('email')}
                  value={values.email}
                  error={errors.email && touched.email && errors.email}
                  label="Email"
                  placeholder="Your email"
                  width="100%"
                  disabled={isSubmitting}
                />
                {showCustomError && (
                  <div className="flex justify-center">
                    <p className="text-red-400 text-xs">
                      This account does not exist,&nbsp;
                      <span
                        className="text-xs font-bold underline inline"
                        onClick={() => {
                          navigate('main-sign-up');
                        }}
                      >
                        sign up
                      </span>
                      &nbsp;instead?
                    </p>
                  </div>
                )}
                <Button type="submit" isLoading={isSubmitting} size="lg">
                  Retrieve Password
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ForgetPasswordForm;
