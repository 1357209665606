import { useEffect } from 'react';
import { Message } from 'app/seedly-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthErrorMessage } from 'app/entity/auth/authDispatch';
import { RootState } from 'app/reducer';

const useHandleUnverifiedEmailError = ({
  onEmailNotVerified = () => {},
  onError = () => {},
} = {}) => {
  const dispatch = useDispatch();
  const errorMessage = useSelector(
    (state: RootState) => state.auth.api.errorMessage,
  );

  useEffect(() => {
    if (errorMessage) {
      const emailNotVerified = errorMessage.indexOf('confirmation email') >= 0;

      if (emailNotVerified) {
        onEmailNotVerified();
      } else if (errorMessage) {
        Message.error(errorMessage);
        onError();
      }

      dispatch(setAuthErrorMessage(''));
    }
  }, [dispatch, errorMessage, onEmailNotVerified, onError]);
};

export default useHandleUnverifiedEmailError;
