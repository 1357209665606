import React from 'react';
import Image from 'next/image';
import Button from 'seedly-component-library/button-new';
import { imageUrl } from 'theme';
import buildConfig from 'utils/buildConfig';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import IconWrapper from './IconWrapper';

interface OwnProps {
  title?: string;
  trackingParam?: string;
  isSignup?: boolean;
  isIconOnly?: boolean;
}

const AppleLoginButton = ({
  title = 'Continue with Apple',
  trackingParam,
  isSignup = false,
  isIconOnly,
}: OwnProps) => {
  const handleButtonClick = () => {
    if (trackingParam) {
      mixpanel.track(trackedEvents.LoginSignUpModal, { param: trackingParam });
    }
    const redirectUrl = window.location.href;

    if (isSignup) {
      sessionStorage.setItem('user-signedup', 'true');
    }
    window.location.href = `${buildConfig.apiDomain}/auth/apple?origin=${redirectUrl}`;
  };

  if (isIconOnly) {
    return (
      <IconWrapper onClick={handleButtonClick}>
        <Image width={20} height={20} src={imageUrl.apple} loading="lazy" />
      </IconWrapper>
    );
  }

  return (
    <Button
      onClick={handleButtonClick}
      variant="outlineGrey"
      isBlock
      size="lg"
      className="bg-neutral-700 hover:bg-neutral-600"
    >
      <div className="flex items-center gap-2 align-middle">
        <Image
          height={17}
          width={20}
          src={imageUrl.appleWhite}
          loading="lazy"
        />
        <p className="text-sm font-semibold text-white">{title}</p>
      </div>
    </Button>
  );
};

export default AppleLoginButton;
