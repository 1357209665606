import React from 'react';
import Text from '../text';
import Space from '../space';
import Input, { InputProps } from './index';

interface OwnProps extends Omit<InputProps, 'error'> {
  label?: string;
  error?: string;
  info?: string;
  width?: string;
}

const FormInput = React.forwardRef<HTMLInputElement, OwnProps>((props, ref) => {
  const { label, error, info, width = 'auto' } = props;

  return (
    <Space width={width} direction="column" size="xxs" align="start">
      {label && (
        <Text variant="titlemd" intent="secondary">
          {label}
        </Text>
      )}
      <Input ref={ref} {...props} error={!!error} />
      {info && (
        <Text variant="caption" intent="secondary">
          {info}
        </Text>
      )}
      {error && (
        <Text variant="caption" intent="error">
          {error}
        </Text>
      )}
    </Space>
  );
});

export default FormInput;
