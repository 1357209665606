import colors from '../themes/colors';
import {
  VariantKey,
  VariantState,
  VariantTheme,
  ShapeTheme,
  ShapeKey,
  SizeKey,
  SizeTheme,
} from './InputTypes';

type VariantStateType = Record<VariantState, VariantTheme>;

export const InputVariantTheme: Record<VariantKey, VariantStateType> = {
  outline: {
    enabled: {
      backgroundColor: colors.neutral1,
      border: `1px solid ${colors.neutral4}`,
    },
    hover: {
      backgroundColor: colors.neutral1,
      border: `1px solid ${colors.seedlyBlue}`,
    },
    active: {
      border: `2px solid ${colors.seedlyBlue}`,
    },
    error: {
      backgroundColor: colors.neutral1,
      border: `1px solid ${colors.red5}`,
    },
    disabled: {
      color: colors.neutral4,
      backgroundColor: colors.neutral3,
      border: `1px solid ${colors.neutral4}`,
    },
  },
  solid: {
    enabled: {
      backgroundColor: colors.neutral2,
      border: 'none',
    },
    hover: {
      backgroundColor: colors.neutral2,
      border: 'none',
    },
    active: {
      backgroundColor: colors.neutral2,
      border: 'none',
    },
    error: {
      backgroundColor: colors.red2,
      border: 'none',
    },
    disabled: {
      color: colors.neutral4,
      backgroundColor: colors.neutral2,
      border: 'none',
    },
  },
};

export const InputShapeTheme: Record<ShapeKey, ShapeTheme> = {
  rounded: {
    borderRadius: '4px',
  },
  pill: {
    borderRadius: '24px',
  },
};

export const InputSizeTheme: Record<SizeKey, SizeTheme> = {
  sm: {
    height: '32px',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '150%',
  },
  md: {
    height: '40px',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '150%',
  },
  lg: {
    height: '48px',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '150%',
  },
};
