import React from 'react';
import clsx from 'clsx';

const IconWrapper = props => {
  const { children, className } = props;

  return (
    <div
      className={clsx(
        'min-h-[40px] min-w-[40px] border border-neutral-700 p-[9px] pb-[3px] cursor-pointer rounded-full',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default IconWrapper;
