import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { imageUrl } from 'theme';
import { RootState } from 'reducer';
import buildConfig from 'utils/buildConfig';
import LoginButton from '../LoginButton';
import { LoginContext } from './LoginContext';

const MainScene = () => {
  const { navigate } = useContext(LoginContext);

  const { image, title, description } = useSelector(
    (state: RootState) => state.modal.loginModalMeta,
  );

  return (
    <div className="flex flex-col gap-6 items-center">
      <div className="flex flex-col gap-1 items-center">
        <img
          className="h-[120px] w-[120px]"
          src={image || imageUrl.login}
          alt="login"
        />
        <p className="text-2xl font-bold text-center">{title}</p>
        {description && (
          <p className="text-neutral-500 text-center">
            {description.split('\n').map(desc => {
              return desc;
            })}
          </p>
        )}
      </div>
      <div className="flex flex-col gap-3 w-full items-center">
        <LoginButton
          title="Sign up with Google"
          variant="google"
          trackingParam="sign_up_google"
          isSignup
        />
        <LoginButton
          title="Sign up with Facebook"
          variant="facebook"
          trackingParam="sign_up_facebook"
          isSignup
        />
        <LoginButton
          title="Sign up with Apple"
          variant="apple"
          trackingParam="sign_up_apple"
          isSignup
        />
        <LoginButton variant="email" />
      </div>
      <p className="text-xs text-neutral-500 text-center">
        By signing up, you agree to accept Seedly’s{' '}
        <a
          className="text-xs text-blue-500"
          target="_blank"
          rel="noreferrer"
          href={`${buildConfig.apiHost}/terms/`}
        >
          Terms & Subscription Agreement and Privacy Policy.
        </a>
      </p>
      <div className="horizontal-divider" />
      <p>
        Already have an account?{' '}
        <span
          className="text-sm font-bold text-blue-500 cursor-pointer"
          onClick={() => navigate('main-log-in')}
        >
          Log in
        </span>
      </p>
    </div>
  );
};

export default MainScene;
