import { useEffect } from 'react';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';
import { useSelector } from 'react-redux';
import { RootState } from 'app/reducer';

const useTrackOpenLogin = () => {
  const loginModalMeta = useSelector(
    (state: RootState) => state.modal.loginModalMeta,
  );

  useEffect(() => {
    if (loginModalMeta.ac) {
      let additionalProps = {};

      if (loginModalMeta.additionalMixpanelProps) {
        additionalProps = loginModalMeta.additionalMixpanelProps.reduce(
          (accum, cur) => {
            if (loginModalMeta[cur]) {
              accum[cur] = loginModalMeta[cur];
            }
            return accum;
          },
          {},
        );
      }

      mixpanel.track(trackedEvents.OpenLoginModal, {
        ac: loginModalMeta.ac,
        ...additionalProps,
      });
    }
  }, [loginModalMeta, loginModalMeta.ac]);
};

export default useTrackOpenLogin;
