import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { imageUrl } from 'theme';
import Button from 'seedly-component-library/button-new';
import { closeLoginModal } from 'app/dispatcher/modalDispatcher';

import { LoginContext } from './LoginContext';

const VerifyEmailScene = () => {
  const dispatch = useDispatch();
  const { reset } = useContext(LoginContext);

  return (
    <div className="flex flex-col items-center w-full gap-4">
      <img
        className="h-[120px] w-[120px]"
        src={imageUrl.verifyEmail}
        alt="verify"
      />
      <div className="flex flex-col items-center gap-1">
        <p className="text-base font-bold text-center">
          Please verify your email!
        </p>
        <p className="text-neutral-500 text-center">
          We have sent you another verification link to your email, please
          verify before you can login.
        </p>
      </div>
      <Button
        size="lg"
        onClick={() => {
          reset();
          dispatch(closeLoginModal());
        }}
      >
        Continue without logging in
      </Button>
    </div>
  );
};

export default VerifyEmailScene;
