import React, { useContext } from 'react';
import Button from 'seedly-component-library/button-new';
import { Mail } from 'react-feather';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import IconWrapper from './IconWrapper';

import { LoginContext } from '../LoginModal/LoginContext';

const EmailLoginButton = (props: { isIconOnly?: boolean }) => {
  const { isIconOnly } = props;
  const { scene, navigate } = useContext(LoginContext);

  const handleButtonClick = () => {
    if (scene === 'main-log-in') {
      navigate('log-in');
    } else if (scene === 'main-sign-up') {
      mixpanel.track(trackedEvents.LoginSignUpModal, {
        param: 'sign_up_email',
      });
      navigate('sign-up');
    }
  };

  if (isIconOnly) {
    return (
      <IconWrapper onClick={handleButtonClick}>
        <Mail width={20} height={20} />
      </IconWrapper>
    );
  }

  return (
    <Button
      onClick={handleButtonClick}
      isBlock
      size="lg"
      variant="outline"
      className="align-middle border-neutral-700 text-neutral-700 hover:bg-neutral-300 active:bg-neutral-400"
    >
      <div className="flex items-center gap-2 google-container">
        <Mail width={20} height={20} />
        <p className="text-sm font-semibold">Sign up with Email</p>
      </div>
    </Button>
  );
};

export default EmailLoginButton;
